import { CameraState } from '@curvewise/common-types'
import { EventEmitter } from 'events'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { ControlAdapter } from '../interaction/control-adapter'
import { CanvasAdapter } from './canvas'

export interface CanvasContextState {
  mainObject?: THREE.Mesh
  mainObjectGeometry?: THREE.BufferGeometry
  camera?: THREE.OrthographicCamera
  controls?: ControlAdapter
  canvas?: CanvasAdapter
}

export interface CanvasContextEvent {
  setMainObject: (mesh: THREE.Mesh | undefined) => void
  setMainObjectGeometry: (mesh: THREE.BufferGeometry) => void
  setCamera: (camera: THREE.OrthographicCamera) => void
  setControls: (controls: ControlAdapter) => void
  setCanvas: (canvas: CanvasAdapter) => void
  cameraDidUpdate: (state: CameraState) => void
  controlDidStart: () => void
  controlDidEnd: () => void
  controlDidSleep: (state: CameraState) => void
}

interface CanvasEmitter {
  // matches EventEmitter.on
  on<U extends keyof CanvasContextEvent>(
    event: U,
    listener: CanvasContextEvent[U]
  ): this

  // matches EventEmitter.off
  off<U extends keyof CanvasContextEvent>(
    event: U,
    listener: CanvasContextEvent[U]
  ): this

  // matches EventEmitter.emit
  emit<U extends keyof CanvasContextEvent>(
    event: U,
    ...args: Parameters<CanvasContextEvent[U]>
  ): boolean
}

let nextId = 0

export class CanvasContext {
  private id: number
  private state: CanvasContextState
  private readonly emitter: CanvasEmitter

  constructor() {
    this.id = nextId++
    this.state = {}
    this.emitter = new EventEmitter()
    this.emitter.on('setMainObject', (mainObject: THREE.Mesh | undefined) => {
      this.state = { ...this.state, mainObject }
    })
    this.emitter.on(
      'setMainObjectGeometry',
      (mainObjectGeometry: THREE.BufferGeometry) => {
        this.state = { ...this.state, mainObjectGeometry }
      }
    )
    this.emitter.on('setCamera', (camera: THREE.OrthographicCamera) => {
      this.state = { ...this.state, camera }
    })
    this.emitter.on('setControls', (controls: ControlAdapter) => {
      this.state = { ...this.state, controls }
    })
    this.emitter.on('setCanvas', (canvas: CanvasAdapter) => {
      this.state = { ...this.state, canvas }
    })
  }

  getState(): CanvasContextState {
    return { ...this.state }
  }

  // matches EventEmitter.on
  on<U extends keyof CanvasContextEvent>(
    event: U,
    listener: CanvasContextEvent[U]
  ): void {
    this.emitter.on(event, listener)
  }

  // matches EventEmitter.off
  off<U extends keyof CanvasContextEvent>(
    event: U,
    listener: CanvasContextEvent[U]
  ): void {
    this.emitter.off(event, listener)
  }

  emit<U extends keyof CanvasContextEvent>(
    event: U,
    ...args: Parameters<CanvasContextEvent[U]>
  ): boolean {
    return this.emitter.emit(event, ...args)
  }
}

export const ReactCanvasContext = createContext<CanvasContext>(
  new CanvasContext()
)

export function CanvasContextProvider({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  const context =
    useRef<CanvasContext>() as React.MutableRefObject<CanvasContext>
  if (!context.current) {
    context.current = new CanvasContext()
  }

  return (
    <ReactCanvasContext.Provider value={context.current}>
      {children}
    </ReactCanvasContext.Provider>
  )
}

export function useGetCanvasContextState(): () => CanvasContextState {
  const canvasContext = useContext(ReactCanvasContext)
  return useCallback(() => canvasContext.getState(), [canvasContext])
}

export function useCanvasEvent<EventName extends keyof CanvasContextEvent>(
  name: EventName,
  cb: CanvasContextEvent[EventName]
): void {
  const canvasContext = useContext(ReactCanvasContext)

  useEffect(() => {
    canvasContext.on(name, cb)
    return () => {
      canvasContext.off(name, cb)
    }
  }, [canvasContext, name, cb])
}

export function useCanvas(): CanvasAdapter | undefined {
  const canvasContext = useContext(ReactCanvasContext)

  const [canvas, setCanvas] = useState<CanvasAdapter | undefined>(
    () => canvasContext.getState().canvas
  )

  useEffect(() => {
    canvasContext.on('setCanvas', setCanvas)
    return () => {
      canvasContext.off('setCanvas', setCanvas)
    }
  }, [canvasContext, setCanvas])

  return canvas
}

export function useControls(): ControlAdapter | undefined {
  const canvasContext = useContext(ReactCanvasContext)

  const [controls, setControls] = useState<ControlAdapter | undefined>(
    () => canvasContext.getState().controls
  )

  useEffect(() => {
    canvasContext.on('setControls', setControls)
    return () => {
      canvasContext.off('setControls', setControls)
    }
  }, [canvasContext, setControls])

  return controls
}

export function useMainObject(): THREE.Mesh | undefined {
  const canvasContext = useContext(ReactCanvasContext)

  const [mainObject, setMainObject] = useState<THREE.Mesh | undefined>(
    () => canvasContext.getState().mainObject
  )

  useEffect(() => {
    canvasContext.on('setMainObject', setMainObject)
    return () => {
      canvasContext.off('setMainObject', setMainObject)
    }
  }, [canvasContext, setMainObject])

  return mainObject
}

export function useMainObjectGeometry(): THREE.BufferGeometry | undefined {
  const canvasContext = useContext(ReactCanvasContext)

  const [mainObjectGeometry, setMainObjectGeometry] = useState<
    THREE.BufferGeometry | undefined
  >(() => canvasContext.getState().mainObjectGeometry)

  useEffect(() => {
    canvasContext.on('setMainObjectGeometry', setMainObjectGeometry)
    return () => {
      canvasContext.off('setMainObjectGeometry', setMainObjectGeometry)
    }
  }, [canvasContext, setMainObjectGeometry])

  return mainObjectGeometry
}

export function useCamera(): THREE.OrthographicCamera | undefined {
  const canvasContext = useContext(ReactCanvasContext)

  const [camera, setCamera] = useState<THREE.OrthographicCamera | undefined>(
    () => canvasContext.getState().camera
  )

  useEffect(() => {
    canvasContext.on('setCamera', setCamera)
    return () => {
      canvasContext.off('setCamera', setCamera)
    }
  }, [canvasContext, setCamera])

  return camera
}
